<template>
  <div>
    <v-btn
      color="primary"
      height="48"
      depressed
      @click="$router.push('/checkout')"
      >Renew Account</v-btn
    >
    <v-divider class="mb-4 mt-4" />
    <p>
      Your 12 months of edits has expired but you can still renew your account
      for another year for ${{ price }}.
    </p>
    <p>
      If you don't renew your account before {{ expDate }}, then you will need
      to repurchase your Will at full price if you wish to make updates in the
      future.
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "AccessLapsed",
  props: ["price", "plan"],
  data: () => ({
    tab: 0,
    cancelProcessing: false,
    subscriptionLoading: false,
  }),
  computed: {
    ...mapGetters("account", ["user_access"]),
    ...mapGetters("app", ["pricing"]),
    expDate() {
      return `${this.user_access.value.expiryDate.day}/${this.user_access.value.expiryDate.month}/${this.user_access.value.expiryDate.year}`
    },
  },
}
</script>
